<template>
  <div class="wrap">
    <div>
      <div class="bread">
        <img class="add" src="@/assets/address.png" />
        <span>当前位置：</span>
        <router-link to="/">首页</router-link>
        <img class="right" src="@/assets/breadr.png" />
        <router-link :to="{ name: 'Media' }">媒体聚焦</router-link>
      </div>
      <div class="media-wrap">
        <div class="top">
          <router-link to="/media/wenhua">
            <img src="../../assets/caption/wenhua.png" alt="" />
          </router-link>
          <router-link to="/media/huodong">
            <img src="../../assets/caption/huodong.png" alt="" />
          </router-link>
          <router-link to="/media/spzx">
            <img src="../../assets/caption/meiti.png" alt="" />
          </router-link>
          <router-link to="/media/peixun">
            <img src="../../assets/caption/peixun.png" alt="" />
          </router-link>
          <router-link to="/media/wccp">
            <img src="../../assets/caption/chanpin.png" alt="" />
          </router-link>
        </div>
        <div class="tab-wrap">
          <div class="tab">
            <a
              v-for="item in types"
              :key="item.id + 'type'"
              :class="{ active: nowType === item.id }"
              @click="serachType(item.id)"
              href="javascript:;"
              >{{ item.name }}</a
            >
          </div>
        </div>
        <div class="content">
          <ul class="text-list">
            <li v-for="item in news" :key="item.id + 'news'">
              <router-link
                :to="{ name: 'MediaDetail', params: { id: item.id } }"
              >
                <div class="left">
                  <p class="title ellipsis-s">{{ item.title }}</p>
                  <span class="time">{{ item.createtime | formatdate }}</span>
                  <p class="des ellipsis-s">{{ item.sub_title }}</p>
                  <span class="btn">查看详情+</span>
                </div>
                <div class="right">
                  <img :src="item.image" />
                </div>
              </router-link>
            </li>
          </ul>
          <div class="pc-page">
            <!-- <paginate
              :page-count="pagination.pageCount"
              :page-range="pagination.pageRange"
              :margin-pages="pagination.marginPages"
              :click-handler="clickCallback"
              :prev-text="'<'"
              :next-text="'>'"
              :container-class="'pagination'"
              :page-class="'page-item'"
            >
            </paginate> -->
          </div>
          <!-- <div class="m-page">
            <a href="javascript:;">加载更多</a>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Paginate from 'vuejs-paginate'
import { getNewType, getNews } from "@/api";
import { formatDate } from "@/utils";
export default {
  name: "Media",
  filters: {
    breadTitle(route) {
      const obj = {
        qyjj: "企业简介",
        fzlc: "发展历程",
        qywh: "企业文化",
        zzry: "资质荣誉",
        dsfc: "大师风采",
        dzgy: "锻造工艺",
      };
      return obj[route];
    },
    formatdate(time) {
      return formatDate(new Date(time * 1000), "yyyy-MM-dd");
    },
  },
  components: {
    // Paginate,
  },
  data() {
    return {
      types: [],
      nowType: "",
      news: [],
      pagination: {
        pageCount: 0,
        pageRange: 3,
        marginPages: 2,
        initPage: 1,
      },
    };
  },
  created() {
    getNewType({ type: 1 })
      .then((res) => {
        if (res.data.code) {
          this.types = res.data.data;
          if (this.types.length) {
            this.nowType = this.types[0].id;
            this.init();
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  },
  methods: {
    init() {
      getNews({ type_id: this.nowType })
        .then((res) => {
          if (res.data.code) {
            this.news = res.data.data;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    clickCallback(pageNum) {
      console.log(pageNum);
    },
    serachType(id) {
      this.nowType = id;
      this.init();
    },
  },
};
</script>

<style lang="less" scoped>
@media screen and (min-width: 750px) {
  .wrap {
    // min-width: 1760px;
    background: #f2f2f2;
    > div {
      // width: 1760px;
      margin: 0 auto;
      padding: 0 20px;
      .media-wrap {
        background: #fff;
        padding: 25px 0;
        > .top {
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            margin: 0 10px;
            height: 244px;
          }
        }
        > .tab-wrap {
          margin-top: 24px;
          > .tab {
            text-align: center;
            border-bottom: 1px solid #d8d8d8;
            padding-bottom: 25px;
            margin: 0 32px;
          }
        }

        > .content {
          padding: 50px 80px;
          > .bottom {
            margin-top: 30px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 750px) {
  .wrap {
    background: #f2f2f2;
    > div {
      .media-wrap {
        background: #fff;
        > .top {
          display: flex;
          justify-content: space-between;
          padding: 12px 12px 0;
          img {
            width: 100%;
          }
          > a {
            width: 19.6%;
          }
          
        }
        .tab-wrap {
          padding: 14px 15px;
          background: #fff;
        }
        > .content {
          padding: 0 15px;
          .m-page {
            padding: 20px 0;
          }
        }
      }
    }
  }
}
</style>